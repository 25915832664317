"use client";

import { Icons } from "@/components/icons";
import { Logo } from "@/components/logo/logo";
import { trackClickItiCreate } from "@/lib/metrics/ga/homepage";
import Image from "next/image";
import Link from "next/link";

const Footer = () => {
  return (
    <div
      id="footer-home-page"
      className="flex w-full justify-start border-t border-secondary-20 md:pt-16"
    >
      <div className="mt-8 w-full">
        <div className="flex flex-col gap-8 px-4 md:flex-row md:px-0 lg:px-10">
          <div className="flex flex-1 flex-col gap-6 lg:min-w-[540px]">
            <div className="flex flex-col items-start justify-start gap-4">
              <div className="flex h-8 w-36 items-start justify-center gap-1.5">
                <Logo width={[92, 44]} height={32} />
                <div className="relative h-8 w-11"></div>
              </div>
              <div className="self-stretch text-base font-normal leading-normal text-slate-900">
                5 giây có ngay lịch trình “la kà” thế giới
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <div className="font-semibold text-gray-800">THEO DÕI LAKA</div>
              <div className="flex gap-4">
                <div className="flex h-6 w-80 items-start justify-start gap-6">
                  <a
                    href="https://www.facebook.com/lakavietnam"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icons.facebook className="size-6" aria-label="facebook"/>
                  </a>
                  <a
                    href="https://www.youtube.com/@LakaAi-r4j"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icons.youtube className="size-6" aria-label="youtube"/>
                  </a>
                  <a
                    href="https://www.tiktok.com/@lakavietnam"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icons.tiktok className="size-6" aria-label="tiktok"/>
                  </a>
                  <a
                    href="https://www.instagram.com/lakatravel_vietnam/?img_index=1"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icons.instagram className="size-6" aria-label="tiktok"/>
                  </a>
                  <a
                    href="https://www.threads.net/@lakatravel_vietnam?xmt=AQGzDZDQUuYykZ8JsF2GYqB1kvp5WUQdO2xufTBa7e9V1I0"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icons.thread className="size-6" aria-label="thread"/>
                  </a>
                </div>
              </div>
            </div>
            <div>
              <Link
                href={"/tao-lich-trinh"}
                onClick={() => trackClickItiCreate("FOOTER")}
                className="flex h-14 w-[240px] min-w-[240px] items-center justify-center gap-2 rounded-full bg-slate-900 px-6 py-4"
              >
                <Icons.staricon className="size-11" />
                <div className="text-nowrap font-semibold text-white">
                  Tạo lịch trình ngay
                </div>
              </Link>
            </div>
          </div>
          <div className="flex-1">
            <div className="flex flex-col items-start justify-start gap-12">
              <div className="flex flex-col items-start justify-start gap-4 self-stretch">
                <div className="self-stretch text-lg font-semibold uppercase leading-snug text-slate-900">
                  Về laka
                </div>
                <div className="self-stretch text-base font-medium leading-normal text-slate-900">
                  <a href="/about">Về chúng tôi</a>
                </div>
                <div className="self-stretch text-base font-medium leading-normal text-slate-900">
                  <a href="https://blog.laka.ai/">Laka blog</a>
                </div>
              </div>
              <div className="flex flex-col items-start justify-start gap-4 self-stretch">
                <div className="self-stretch text-lg font-semibold uppercase leading-snug text-slate-900">
                  Sản phẩm
                </div>
                <div className="self-stretch text-base font-medium leading-normal text-slate-900">
                  <a href="/tao-lich-trinh">Tạo kế hoạch du lịch</a>
                </div>
                <div className="self-stretch text-base font-medium leading-normal text-slate-900">
                  <a href="/hoat-dong-dich-vu">Hoạt động & dịch vụ</a>
                </div>
              </div>
              <div className="flex flex-col items-start justify-start gap-4 self-stretch">
                <div className="self-stretch text-lg font-semibold uppercase leading-snug text-slate-900">
                  Khác
                </div>
                <div className="self-stretch text-base font-medium leading-normal text-slate-900">
                  <a href="/terms-of-service#terms-of-service">
                    Điều khoản sử dụng
                  </a>
                </div>
                <div className="self-stretch text-base font-medium leading-normal text-slate-900">
                  <a href="/terms-of-service#privacy-policy">
                    Chính sách bảo mật
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1">
            <div className="font-semibold text-gray-800">LIÊN HỆ</div>
            <div className="mt-6 flex gap-4">
              <div className="flex h-8 w-56 items-start justify-start gap-6">
                <a
                  href="https://zalo.me/3560002558709178527"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icons.zaloicon className="size-6" aria-label="zaloicon"/>
                </a>
                <a
                  href="https://www.facebook.com/lakavietnam"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icons.messengericon className="size-6" aria-label="messengericon"/>
                </a>
                <a
                  href="mailto:care@laka.ai"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icons.googleicon className="size-6" aria-label="googleicon"/>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-8 bg-gray-900 py-9 lg:mt-16">
          <div className="container mx-auto flex items-center justify-between">
            <div className="flex flex-wrap gap-4">
              <a
                href="http://online.gov.vn/Home/WebDetails/113213"
                target="_blank"
              >
                <Image
                  width={111}
                  height={42}
                  className="h-[42px] w-[111px] object-contain"
                  src="/images/image-MOIT-registered.png"
                  alt={"Logo đã thông báo bộ công thương"}
                />
              </a>
              <div className="flex h-8 w-14 items-center justify-center rounded-md border border-gray-100 bg-white px-px py-2">
                <Icons.visaCard className="relative size-8" />
              </div>
              <div className="flex h-8 w-14 items-center justify-center rounded-md border border-gray-100 bg-white px-px py-2">
                <Icons.masterCard className="relative size-8" />
              </div>
              <div className="flex h-8 w-14 items-center justify-center rounded-md border border-gray-100 bg-white px-px py-2">
                <Icons.jcbCard className="relative size-8" />
              </div>
              <div className="flex h-8 w-14 items-center justify-center rounded-md border border-gray-100 bg-white px-px py-2">
                <Image
                  width={32}
                  height={32}
                  src="https://asset.brandfetch.io/idihpQ2rue/idmK2YRJ_I.svg"
                  alt="image-onePay"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

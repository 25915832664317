import { trackAmplitudeEvent } from "@/components/amplitude-provider";
import useAppStore from "@/components/provider/app-store";

declare global {
  interface Window {
    gtag(type: "config", googleAnalyticsId: string, options: any);
    gtag(type: "event", eventAction: string, fieldObject: any);
  }
}

export const sendEventGA = (
  action: string,
  value: { [key: string]: string | number | boolean } = {}
) => {
  if (typeof window !== "undefined" && window.gtag) {
    const user = useAppStore.getState().user;
    value.uid = user?.id || "";
    console.log("event_GA:", action, value);
    trackAmplitudeEvent(action, value);
    window.gtag("event", action, value);
  }
};

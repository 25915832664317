import { sendEventGA } from "@/lib/google/google-analytics";
import { useEffect } from "react";

export const useTrackViewHomepage = () => {
  useEffect(() => {
    sendEventGA("homepage_view", {});
  }, []);
};
export const trackClickSearchBox = () => {
  sendEventGA("hp_iti_create_searchbox_click", {});
};
export const trackClickItiCreateHeader = () => {
  sendEventGA("hp_iti_create_header_click", {});
};
export const trackClickItiCreate = (
  type: "IMAGE" | "EXPLORE" | "SERVICE" | "FOOTER"
) => {
  if (type === "IMAGE") sendEventGA("hp_iti_create_image_click", {});
  if (type === "EXPLORE") sendEventGA("hp_iti_create_explore_click", {});
  if (type === "SERVICE") sendEventGA("hp_it_create_service_click", {});
  if (type === "FOOTER") sendEventGA("hp_it_create_footer_click", {});
};
export const trackClickEcomHeader = () => {
  sendEventGA("hp_ecom_header_click", {});
};
export const trackClickServiceViewAll = () => {
  sendEventGA("hp_service_viewall_click", {});
};
export const trackClickServiceDetail = (aid: string) => {
  sendEventGA("hp_service_detail_click", { aid });
};
export const trackClickExploreViewAll = () => {
  sendEventGA("hp_explore_viewall_click", {});
};
export const trackClickExploreDetail = () => {
  sendEventGA("hp_explore_detail_click", {});
};
export const trackClickSignIn = () => {
  sendEventGA("hp_signin_click", {});
};
export const trackClickCart = () => {
  sendEventGA("hp_cart_click", {});
};
export const trackClickMyAccount = () => {
  sendEventGA("hp_myaccount_click", {});
};

"use client";

import { cn } from "@/lib/utils";
import { string } from "prop-types";
import * as React from "react";

interface logoProps {
  className?: React.HTMLAttributes<HTMLElement>;
  width?: [string | number, string | number];
  height?: string | number;
  fill?: string;
}

export function Logo({
  className,
  width = ["83", "39"],
  height = "31",
  fill = "#526FE3",
}: logoProps) {
  return (
    <div className={cn("", className)}>
      <div className="mb-1 flex justify-center">
        <div className=" mb-1 mr-1">
          <svg
            width={width[0]}
            height={height}
            viewBox="0 0 83 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M4.95382 28.15H0V0H4.95382V28.15Z" fill={fill} />
            <path
              d="M24.0806 11.1443V8.86917H29.0344V28.15H24.0806V25.8748C22.5829 27.7644 20.5092 28.6898 17.8211 28.6898C15.2482 28.6898 13.0593 27.7258 11.216 25.7592C9.41113 23.7925 8.48948 21.3631 8.48948 18.5096C8.48948 15.656 9.41113 13.2652 11.216 11.2986C13.0593 9.33191 15.2482 8.32931 17.8211 8.32931C20.5092 8.32931 22.5829 9.25479 24.0806 11.1443ZM14.941 22.4429C15.9394 23.4455 17.2067 23.9468 18.7427 23.9468C20.2788 23.9468 21.5461 23.4455 22.5445 22.4429C23.5814 21.4017 24.0806 20.0906 24.0806 18.5096C24.0806 16.9286 23.5814 15.6175 22.5445 14.6149C21.5461 13.5737 20.2788 13.0724 18.7427 13.0724C17.2067 13.0724 15.9394 13.5737 14.941 14.6149C13.9425 15.6175 13.4433 16.9286 13.4433 18.5096C13.4433 20.0906 13.9425 21.4017 14.941 22.4429Z"
              fill={fill}
            />
            <path
              d="M43.2806 18.3939L51.2297 28.15H45.4695L38.4803 19.3965V28.15H33.5265V1.15685H38.4803V17.3527L45.0854 8.86917H50.9993L43.2806 18.3939Z"
              fill={fill}
            />
            <path
              d="M66.9075 11.1443V8.86917H71.8614V28.15H66.9075V25.8748C65.4099 27.7644 63.3362 28.6898 60.648 28.6898C58.0751 28.6898 55.8862 27.7258 54.0429 25.7592C52.2381 23.7925 51.3164 21.3631 51.3164 18.5096C51.3164 15.656 52.2381 13.2652 54.0429 11.2986C55.8862 9.33191 58.0751 8.32931 60.648 8.32931C63.3362 8.32931 65.4099 9.25479 66.9075 11.1443ZM57.7679 22.4429C58.7664 23.4455 60.0336 23.9468 61.5697 23.9468C63.1058 23.9468 64.373 23.4455 65.3715 22.4429C66.4083 21.4017 66.9075 20.0906 66.9075 18.5096C66.9075 16.9286 66.4083 15.6175 65.3715 14.6149C64.373 13.5737 63.1058 13.0724 61.5697 13.0724C60.0336 13.0724 58.7664 13.5737 57.7679 14.6149C56.7695 15.6175 56.2702 16.9286 56.2702 18.5096C56.2702 20.0906 56.7695 21.4017 57.7679 22.4429Z"
              fill={fill}
            />
            <path
              d="M81.5377 27.6872C80.2704 28.9598 78.1967 28.9598 76.9295 27.6872C75.6622 26.4147 75.6622 24.3324 76.9295 23.0599C78.1967 21.7873 80.2704 21.7873 81.5377 23.0599C82.8049 24.3324 82.8049 26.4147 81.5377 27.6872Z"
              fill={fill}
            />
          </svg>
        </div>
        <div>
          <svg
            width={width[1]}
            height={height}
            viewBox="0 0 39 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.588867 14.5C0.588867 6.49187 6.95826 0 14.8153 0H24.7738C32.6308 0 39.0002 6.49187 39.0002 14.5C39.0002 22.5081 32.6308 29 24.7738 29H14.8153C6.95826 29 0.588867 22.5081 0.588867 14.5Z"
              fill={`${
                fill === "#111B42" ? "#111B42" : "url(#paint0_linear_1088_3359)"
              }`}
            />
            <path
              d="M23.568 21.7499H20.6177L19.7916 19.1399H14.3041L13.478 21.7499H10.5474L15.3662 7.24988H18.7295L23.568 21.7499ZM17.0577 10.5435L15.1695 16.4677H18.9458L17.0577 10.5435Z"
              fill="white"
            />
            <path
              d="M24.9048 21.7499V7.24988H27.6191V21.7499H24.9048Z"
              fill="white"
            />
            <defs>
              <linearGradient
                id="paint0_linear_1088_3359"
                x1="19.7945"
                y1="0"
                x2="19.7945"
                y2="29"
                gradientUnits="userSpaceOnUse"
              >
                <stop
                  stopColor={`${fill === "#111B42" ? "#111B42" : "#526FE3"}`}
                />
                <stop
                  offset="1"
                  stopColor={`${fill === "#111B42" ? "#111B42" : "#D752E3"}`}
                />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
    </div>
  );
}
